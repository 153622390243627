.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -16px;
  width: auto;
  padding: 20px;
}

.my-masonry-grid_column {
  padding-left: 16px;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 16px;
}
